import { createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

const client = createClient({
  projectId: '8quaz284', 
  dataset: 'production', 
  apiVersion: '2021-08-31', 
  token: "skrNnhdacTaxVcZyCAVvHy6EU8eUWffdEVfMG05ed0oS3L6RssxRoe4L56aUUMGCiDM4rrdyvI9raztfd4nrw3bYpGuKYX0h5LHjAjcWjbGCK1Ue6Fi4MsU3sWq4u0yi7R5TK5gqlfDzRYKVx6uoIIeqEnDEkzMq0iKD6MmFBJWlC5KY4geo", 
  useCdn: true, 
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => {
  return builder.image(source);
};

export default client;





