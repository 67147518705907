import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../configs";
import client from "../configs/sanityClient"; // Import your Sanity client

//async thunk for user profile retrieval
export const me = createAsyncThunk("auth/me", async () => {
  let token = JSON.parse(localStorage.getItem("token")).Authorization;
  let configs = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const response = await axios.get(`${config.API_ENDPOINT}/auth/me`, {
    ...configs,
  });

  return response.data;
});

// Register new user
export const register = createAsyncThunk(
  "auth/register",
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${config.API_ENDPOINT}/auth/register`, body);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue("Registration failed. Please try again.");
      }
    }
  }
);

// Login user
export const login = createAsyncThunk(
  "auth/login",
  async (body, { dispatch }) => {
    const response = await axios.post(
      `${config.API_ENDPOINT}/auth/login`,
      body
    );
    let aux = JSON.stringify(response.data);
    localStorage.setItem("token", aux);

    dispatch(me(response.data.Authorization));
    dispatch(setUserInfo(response.data.user));
    dispatch(loginSuccess());

    return response.data;
  }
);

// Async thunk to send verification code for password reset
export const sendVerificationCode = createAsyncThunk(
  "mailer/changePassword",
  async (email, { dispatch, rejectWithValue }) => {
    try {
      const verifCode = Math.floor(100000 + Math.random() * 900000); // Generate random 6-digit code
      const to = email.email;
      
      const response = await axios.post(`${config.API_ENDPOINT}/mailer/changePassword`, {
        to,
        verifCode,
      },
      { 
        headers: { 'Content-Type': 'application/json' } 
      });

      
      dispatch(setVerificationCode(verifCode));
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to send verification code.");
    }
  }
);

// Async thunk to reset password
export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async ({ email, password, confirmPassword }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${config.API_ENDPOINT}/auth/change-password`, {
        email,
        password,
        confirmPassword,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to change password. Please try again.");
    }
  }
);

// New Async thunk to verify email
export const verifyEmail = createAsyncThunk(
  "auth/verifyEmail",
  async (email, {dispatch, rejectWithValue }) => {
    try {
      // Sanity query to check if the email exists and is confirmed
      const query = `*[_type == "utilisateur" && email == $email && confirmation == true][0]{
        email,
      }`;
      
      const utilisateurData = await client.fetch(query, { email });
      console.log(utilisateurData);
      
      if (!utilisateurData) {
        return rejectWithValue("Email n'existe pas ou n'est pas encore confirmé.");
      }
      dispatch(setUserEmailData(utilisateurData.email));
      return utilisateurData; 
    } catch (error) {
      return rejectWithValue("Error checking email.");
    }
  }
);

export const AuthSlice = createSlice({
  name: "auth",
  initialState: {
    userInfo: null,
    me: null,
    error: null,
    isLoggedIn: false,
    verificationSent: false,
    verificationCode: null,
    changePasswordSuccess: false,
    userEmailData: null, 
  },
  reducers: {
    loginSuccess: (state) => {
      state.isLoggedIn = true;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.me = null;
      state.userEmailData = null; 
    },
    setVerificationCode: (state,action) => {
      state.verificationCode = action.payload; 
    },
    setUserEmailData: (state,action) => {
      state.userEmailData = action.payload; 
      state.userInfo = null;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(me.fulfilled, (state, action) => {
        state.me = action.payload;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.registerError = null;
      })
      .addCase(register.rejected, (state, action) => {
        state.registerError = action.payload;
      })
      .addCase(sendVerificationCode.fulfilled, (state) => {
        state.verificationSent = true;
      })
      .addCase(sendVerificationCode.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.changePasswordSuccess = true;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.userEmailData = action.payload; 
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.error = action.payload; 
      });
  },
});

export const { loginSuccess, logout ,setVerificationCode, setUserInfo ,setUserEmailData} = AuthSlice.actions;
export default AuthSlice.reducer;
