import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  IconButton,
  InputAdornment,
  FormControl,
  Divider,
  MenuItem,
  Grid,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CloseIcon from "@mui/icons-material/Close";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { register } from "../store/auth";
import { fetchPays } from "../store/pays";

const SignUpModal = () => {
  const dispatch = useDispatch();
  const { registerError } = useSelector((state) => state.auth || {});
  const pays = useSelector((state) => state.pays.items);

  useEffect(() => {
    dispatch(fetchPays());
  }, [dispatch]);

  const [open, setOpen] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [adresse, setAdresse] = useState("");
  const [password, setPassword] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleRegister = () => {
    let missingFields = 0;

    if (!prenom) missingFields++;
    if (!nom) missingFields++;
    if (!email) missingFields++;
    if (!selectedCountry) missingFields++;
    if (!phoneNumber) missingFields++;
    if (!adresse) missingFields++;
    if (!password) missingFields++;

    if (missingFields > 1) {
      toast.error("Tous les champs sont requis.", { theme: "dark" });
      return;
    }

    if (!prenom) {
      toast.error("Champ prénom est obligatoire.", { theme: "dark" });
      return;
    }
    if (!nom) {
      toast.error("Champ nom est obligatoire.", { theme: "dark" });
      return;
    }
    if (!email) {
      toast.error("Champ email est obligatoire.", { theme: "dark" });
      return;
    }
    if (!selectedCountry) {
      toast.error("Champ pays est obligatoire.", { theme: "dark" });
      return;
    }
    if (!phoneNumber) {
      toast.error("Champ numéro de téléphone est obligatoire.", { theme: "dark" });
      return;
    }
    if (!adresse) {
      toast.error("Champ adresse est obligatoire.", { theme: "dark" });
      return;
    }
    if (!password) {
      toast.error("Champ mot de passe est obligatoire.", { theme: "dark" });
      return;
    }

    const body = {
      prenom: prenom,
      nom: nom,
      email: email,
      paysId: selectedCountry,
      numeroTelephone: phoneNumber,
      adress: adresse,
      password: password,
    };


    dispatch(register(body)).then((response) => {
      if (response.meta.requestStatus === "fulfilled") {
        toast.success("Inscription réussie !", { theme: "dark" });
        handleClose();
      } else {
        toast.error(response.error.message, { theme: "dark" });
      }
    });
  };

  return (
    <Box sx={{ textAlign: "center" }}>
      <Dialog
        open={open}
        
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: "#333",
            color: "#A68E5D",
            borderRadius: 2,
            maxWidth: "500px",
            width: "100%",
            padding: "20px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
            textAlign: "center",
          },
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(4px)",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          },
        }}
      >
        <DialogTitle
          sx={{
            color: "#A68E5D",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            paddingBottom: 0,
            fontSize: "1.5rem",
            textAlign: "center",
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseIcon sx={{ color: "#A68E5D" }} />
          </IconButton>
          <img
            src="https://static.vecteezy.com/system/resources/thumbnails/035/147/887/small/ai-generated-antique-armchair-throne-on-transparent-background-image-png.png"
            alt="Icon"
            style={{ width: "50px", marginBottom: "10px" }}
          />
          Bienvenue sur notre site

        </DialogTitle>
        <Divider
          sx={{
            mt: 2,
            mb: 2,
            backgroundColor: "white",
            height: "1px",
            width: "100%",
          }}
        />
        <DialogContent>
          <FormControl fullWidth variant="outlined" margin="normal">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Prénom"
                  variant="outlined"
                  required
                  fullWidth
                  value={prenom}
                  onChange={(e) => setPrenom(e.target.value)}
                  InputLabelProps={{
                    style: {
                      color: "#A68E5D",
                    },
                  }}
                  InputProps={{
                    style: {
                      backgroundColor: "#444",
                      color: "#A68E5D",
                      borderColor: "#A68E5D",
                      borderRadius: "5px",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#A68E5D",
                      },
                      "&:hover fieldset": {
                        borderColor: "#A68E5D",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#A68E5D",
                      },
                    },
                    "& .MuiInputLabel-outlined.Mui-focused": {
                      color: "#A68E5D",
                    },
                    "& input:-webkit-autofill": {
                      backgroundColor: "#444",
                      color: "#A68E5D",
                      boxShadow: "0 0 0 1000px #444 inset",
                      transition: "background-color 5000s ease-in-out 0s",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Nom"
                  variant="outlined"
                  required
                  fullWidth
                  value={nom}
                  onChange={(e) => setNom(e.target.value)}
                  InputLabelProps={{
                    style: {
                      color: "#A68E5D",
                    },
                  }}
                  InputProps={{
                    style: {
                      backgroundColor: "#444",
                      color: "#A68E5D",
                      borderColor: "#A68E5D",
                      borderRadius: "5px",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#A68E5D",
                      },
                      "&:hover fieldset": {
                        borderColor: "#A68E5D",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#A68E5D",
                      },
                    },
                    "& .MuiInputLabel-outlined.Mui-focused": {
                      color: "#A68E5D",
                    },
                    "& input:-webkit-autofill": {
                      backgroundColor: "#444",
                      color: "#A68E5D",
                      boxShadow: "0 0 0 1000px #444 inset",
                      transition: "background-color 5000s ease-in-out 0s",
                    },
                  }}
                />
              </Grid>
            </Grid>

            <TextField
              label="Adresse e-mail"
              variant="outlined"
              fullWidth
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputLabelProps={{
                style: {
                  color: "#A68E5D",
                },
              }}
              InputProps={{
                style: {
                  backgroundColor: "#444",
                  color: "#A68E5D",
                  borderColor: "#A68E5D",
                  borderRadius: "5px",
                },
              }}
              sx={{
                mb: 2,
                mt: 2,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#A68E5D",
                  },
                  "&:hover fieldset": {
                    borderColor: "#A68E5D",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#A68E5D",
                  },
                },
                "& .MuiInputLabel-outlined.Mui-focused": {
                  color: "#A68E5D",
                },
                "& input:-webkit-autofill": {
                  backgroundColor: "#444",
                  color: "#A68E5D",
                  boxShadow: "0 0 0 1000px #444 inset",
                  transition: "background-color 5000s ease-in-out 0s",
                },
              }}
            />

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  select
                  label="Pays"
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  variant="outlined"
                  required
                  fullWidth
                  InputLabelProps={{
                    style: {
                      color: "#A68E5D",
                    },
                  }}
                  InputProps={{
                    style: {
                      backgroundColor: "#444",
                      color: "#A68E5D",
                      borderColor: "#A68E5D",
                      borderRadius: "5px",
                    },
                  }}
                  sx={{
                    mb: 2,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#A68E5D",
                      },
                      "&:hover fieldset": {
                        borderColor: "#A68E5D",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#A68E5D",
                      },
                    },
                    "& .MuiInputLabel-outlined.Mui-focused": {
                      color: "#A68E5D",
                    },
                  }}
                >
                  {pays.map((country) => (

                    <MenuItem key={country._id} value={country.dbId}>
                      <img
                        src={country?.image[0]?.path}
                        width="22"
                        height="15"
                        style={{ marginRight: "10px" }}
                      />
                      {country.nom}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Numéro de téléphone"
                  variant="outlined"
                  fullWidth
                  required
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  InputLabelProps={{
                    style: {
                      color: "#A68E5D",
                    },
                  }}
                  InputProps={{
                    style: {
                      backgroundColor: "#444",
                      color: "#A68E5D",
                      borderColor: "#A68E5D",
                      borderRadius: "5px",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#A68E5D",
                      },
                      "&:hover fieldset": {
                        borderColor: "#A68E5D",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#A68E5D",
                      },
                    },
                    "& .MuiInputLabel-outlined.Mui-focused": {
                      color: "#A68E5D",
                    },
                    "& input:-webkit-autofill": {
                      backgroundColor: "#444",
                      color: "#A68E5D",
                      boxShadow: "0 0 0 1000px #444 inset",
                      transition: "background-color 5000s ease-in-out 0s",
                    },
                  }}
                />
              </Grid>
            </Grid>

            <TextField
              label="Adresse"
              variant="outlined"
              fullWidth
              required
              value={adresse}
              onChange={(e) => setAdresse(e.target.value)}
              InputLabelProps={{
                style: {
                  color: "#A68E5D",
                },
              }}
              InputProps={{
                style: {
                  backgroundColor: "#444",
                  color: "#A68E5D",
                  borderColor: "#A68E5D",
                  borderRadius: "5px",
                },
              }}
              sx={{
                mb: 2,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#A68E5D",
                  },
                  "&:hover fieldset": {
                    borderColor: "#A68E5D",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#A68E5D",
                  },
                },
                "& .MuiInputLabel-outlined.Mui-focused": {
                  color: "#A68E5D",
                },
                "& input:-webkit-autofill": {
                  backgroundColor: "#444",
                  color: "#A68E5D",
                  boxShadow: "0 0 0 1000px #444 inset",
                  transition: "background-color 5000s ease-in-out 0s",
                },
              }}
            />

            <FormControl fullWidth variant="outlined">
              <TextField
                label="Mot de passe"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputLabelProps={{
                  style: {
                    color: "#A68E5D",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: {
                    backgroundColor: "#444",
                    color: "#A68E5D",
                    borderColor: "#A68E5D",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#A68E5D",
                    },
                    "&:hover fieldset": {
                      borderColor: "#A68E5D",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#A68E5D",
                    },
                  },
                  "& .MuiInputLabel-outlined.Mui-focused": {
                    color: "#A68E5D",
                    "& input:-webkit-autofill": {
                      backgroundColor: "#444",
                      color: "#A68E5D",
                      boxShadow: "0 0 0 1000px #444 inset",
                      transition: "background-color 5000s ease-in-out 0s",
                    },
                  },
                }}
              />
            </FormControl>
          </FormControl>

          <Button
            onClick={handleRegister}
            sx={{
              backgroundColor: '#444',
              color: 'white',
              mt: 2,
              padding: '10px',
              fontSize: '1rem',
              '&:hover': {
                backgroundColor: '#A68E5D',
              },
            }}
          >
            S'inscrire
          </Button>
        </DialogContent>
      </Dialog>
      <ToastContainer />
    </Box >
  );
};

export default SignUpModal;
