import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from '../configs/sanityClient'; 


export const fetchCategories = createAsyncThunk('categories/fetchCategories', async () => {
  const categoriesData = await client.fetch(`*[_type == "categorieProduit"]{
    _id,
    nom
  }`);
  return categoriesData;
});

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    items: [],
    status: 'idle',
    error: null
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.items = action.payload;
        state.status = 'succeeded';
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.error = action.error.message;
      });
  }
});

export default categoriesSlice.reducer;
