import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import client from "../configs/sanityClient";

// Fetch all objets
export const fetchObjets = createAsyncThunk("objet/fetchObjets", async (categorieId) => {
  const query = `*[_type == "objet" ${categorieId ? `&& categorie._ref == "${categorieId}"` : ""}]{
    _id,
    nom,
    prix,
    description,
     createdAt, 
    "categorie": categorie->{
      _id,
      nom
    },
    "pays": pays->{
      _id,
      nom
    },
    "utilisateur": utilisateur->{
      _id,
      prenom,
      nom,
      email,
      numeroTelephone,
    },
    "images": images[]->{path, alt, type , _ref, file},
    dbId,
  }`;
  const objetsData = await client.fetch(query);
  return objetsData;
});

// Fetch a single objet by ID
export const fetchObjetById = createAsyncThunk("objet/fetchObjetById", async (id) => {
  const objetData = await client.fetch(`*[_type == "objet" && _id == $id]{
    _id,
    nom,
    prix,
    description,
     createdAt, 
    "categorie": categorie->{
      _id,
      nom
    },
    "pays": pays->{
      _id,
      nom
    },
    "utilisateur": utilisateur->{
      _id,
      prenom,
      nom,
      email,
      numeroTelephone,
    },
    "images": images[]->{path, alt, type , _ref, file},
    dbId
  }[0]`, { id });

  return objetData;
});

export const latestObjets = createAsyncThunk('objet/fetchLatest', async () => {
  const query = `*[_type == "objet"] | order(createdAt desc) {
    _id,
    nom,
    prix,
    description,
    "categorie": categorie->{
      _id,
      nom
    },
    "pays": pays->{
      _id,
      nom
    },
    "utilisateur": utilisateur->{
      _id,
      prenom,
      nom,
      email,
      numeroTelephone,
    },
    "images": images[]->{path, alt, type, _ref, file},
    dbId,
  }`;
  const objets = await client.fetch(query);
  return objets;
});

const objetSlice = createSlice({
  name: "objet",
  initialState: {
    items: [],
    selectedItem: null,
    status: "idle",
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchObjets.fulfilled, (state, action) => {
        state.items = action.payload;
        state.status = "succeeded";
      })
      .addCase(fetchObjets.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchObjetById.fulfilled, (state, action) => {
        state.selectedItem = action.payload;
        state.status = "succeeded";
      })
      .addCase(fetchObjetById.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export default objetSlice.reducer;
