// store/mailStore.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from "../configs";

// Define async thunks for each email action
export const sendContactVendorEmail = createAsyncThunk(
  'mailer/sendContactVendorEmail',
  async (sendMailDto, { rejectWithValue }) => {
    try {
      await axios.post(`${config.API_ENDPOINT}/mailer/contactVendor`, sendMailDto);
      return 'Email sent to vendor successfully';
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const sendChangePasswordEmail = createAsyncThunk(
  'mailer/sendChangePasswordEmail',
  async (sendMailDto, { rejectWithValue }) => {
    try {
      await axios.post('/mailer/changePassword', sendMailDto);
      return 'Password change email sent successfully';
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const sendContactUsEmail = createAsyncThunk(
  'mailer/sendContactUsEmail',
  async (sendMailDto, { rejectWithValue }) => {
    try {
      await axios.post(`${config.API_ENDPOINT}/mailer/contactUs`, sendMailDto);
      return 'Contact email sent successfully';
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Create a slice to manage the state
const mailSlice = createSlice({
  name: 'mailer',
  initialState: {
    successMessage: '',
    errorMessage: '',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendContactVendorEmail.pending, (state) => {
        state.successMessage = '';
        state.errorMessage = '';
      })
      .addCase(sendChangePasswordEmail.pending, (state) => {
        state.successMessage = '';
        state.errorMessage = '';
      })
      .addCase(sendContactUsEmail.pending, (state) => {
        state.successMessage = '';
        state.errorMessage = '';
      })
      .addCase(sendContactVendorEmail.fulfilled, (state, action) => {
        state.successMessage = action.payload;
        state.errorMessage = '';
      })
      .addCase(sendChangePasswordEmail.fulfilled, (state, action) => {
        state.successMessage = action.payload;
        state.errorMessage = '';
      })
      .addCase(sendContactUsEmail.fulfilled, (state, action) => {
        state.successMessage = action.payload;
        state.errorMessage = '';
      })
      .addCase(sendContactVendorEmail.rejected, (state, action) => {
        state.successMessage = '';
        state.errorMessage = action.payload;
      })
      .addCase(sendChangePasswordEmail.rejected, (state, action) => {
        state.successMessage = '';
        state.errorMessage = action.payload;
      })
      .addCase(sendContactUsEmail.rejected, (state, action) => {
        state.successMessage = '';
        state.errorMessage = action.payload;
      });
  },
});

export default mailSlice.reducer;
