import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  IconButton,
  InputAdornment,
  FormControl,
  Divider,
  Link,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { login } from "../store/auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ResetPasswordModal from './resetPassword'; 
import CloseIcon from '@mui/icons-material/Close';


const LoginModal = ({ onClose, handleSignup }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true); 
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [resetPasswordOpen, setResetPasswordOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogin = () => {
    let missingFields = 0;
    if (!email) missingFields++;
    if (!password) missingFields++;

    if (missingFields > 1) {
      toast.error("Tous les champs sont requis.", { theme: "dark" });
      return;
    }
    if (!email) {
      toast.error("Champ email est obligatoire.", { theme: "dark" });
      return;
    }
    if (!password) {
      toast.error("Champ mot de passe est obligatoire.", { theme: "dark" });
      return;
    }

    const body = {
      email: email,
      password: password,
    };

    dispatch(login(body))
      .then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          toast.success("Connection réussie !", { theme: "dark" });
          handleClose();
        } else {
          const errorMessage = response.payload?.message || "Erreur lors de la connexion.";
          toast.error(errorMessage, { theme: "dark" });
        }
      })
      .catch((error) => {
        toast.error(error.message || "Une erreur inattendue est survenue.", { theme: "dark" });
      });
  };

  const handleForgotPassword = () => {
    setResetPasswordOpen(true); 
    setOpen(false); 
  };

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: '#3c3c3c',
            color: '#A68E5D',
            borderRadius: 2,
            maxWidth: '400px',
            width: '100%',
            padding: '20px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
            textAlign: 'center',
          },
          '& .MuiBackdrop-root': {
            backdropFilter: 'blur(4px)',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          },
        }}
      >
                <IconButton
          onClick={handleClose}
          sx={{ position: 'absolute', top: 10, right: 10, color: '#A68E5D' }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          sx={{
            color: '#A68E5D',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            paddingBottom: 0,
            fontSize: '1.5rem',
            textAlign: 'center',
          }}
        >
          <img
            src="https://static.vecteezy.com/system/resources/thumbnails/035/147/887/small/ai-generated-antique-armchair-throne-on-transparent-background-image-png.png"
            alt="Icon"
            style={{ width: '50px', marginBottom: '10px' }}
          />
          Log in to your account
        </DialogTitle>
        <Divider
          sx={{
            mt: 2,
            mb: 2,
            backgroundColor: 'white',
            height: '1px',
            width: '100%',
          }}
        />
        <DialogContent>
          <FormControl fullWidth variant="outlined" margin="normal">
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputLabelProps={{
                style: { color: 'white' },
              }}
              InputProps={{
                style: { backgroundColor: 'transparent', color: 'white' },
              }}
              sx={{
                mb: 2,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white',
                  },
                  '&:hover fieldset': {
                    borderColor: 'white',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'white',
                  },
                },
                '& .MuiInputLabel-outlined.Mui-focused': {
                  color: 'white',
                },
              }}
            />
            <TextField
              label="Password"
              type={showPassword ? 'text' : 'password'}
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                      sx={{ color: 'white' }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                style: { backgroundColor: 'transparent', color: 'white' },
              }}
              InputLabelProps={{
                style: { color: 'white' },
              }}
              sx={{
                mb: 2,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white',
                  },
                  '&:hover fieldset': {
                    borderColor: 'white',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'white',
                  },
                },
                '& .MuiInputLabel-outlined.Mui-focused': {
                  color: 'white',
                },
              }}
            />
          </FormControl>

          <Link
            onClick={handleForgotPassword}
            underline="hover"
            sx={{
              color: 'white',
              display: 'block',
              textAlign: 'right',
              mb: 2,
              fontSize: '0.9rem',
              cursor: 'pointer',
            }}
          >
            Forgot your password?
          </Link>

          <Button
            variant="contained"
            fullWidth
            onClick={handleLogin}
            sx={{
              backgroundColor: 'grey',
              color: 'white',
              mt: 2,
              padding: '10px',
              fontSize: '1rem',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            Log In
          </Button>
          <Typography
            variant="body2"
            sx={{ mt: 3, color: 'white', fontSize: '0.8rem' }}
          >
            New around here?{' '}
            <Link
              onClick={handleSignup}
              sx={{ color: 'white', textDecoration: 'underline', fontSize: '1.2rem' }}
            >
              Create an account
            </Link>
          </Typography>
        </DialogContent>
      </Dialog>

      <ToastContainer />

      {resetPasswordOpen && (
        <ResetPasswordModal open={resetPasswordOpen} onClose={() => setResetPasswordOpen(false)} />
      )}
    </Box>
  );
};

export default LoginModal;
