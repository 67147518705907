import { z } from "zod";
import { parsePhoneNumberFromString } from "libphonenumber-js";

// Phone validation function
export const validatePhoneNumber = (phone) => {
  const phoneNumber = parsePhoneNumberFromString(phone, "FR");
  return phoneNumber && phoneNumber.isValid() ? true : "Invalid phone number";
};

// Contact form validation schema
export const ContactSchema = z.object({
  fullName: z.string().min(2, "Please enter your full name"),
  email: z.string().email("Please enter a valid email address"),
  phoneNumber: z.string().refine(validatePhoneNumber, "Please enter a valid phone number"),
  message: z.string().min(5, "Please enter a message"),
});
