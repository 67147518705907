import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { latestObjets } from '../store/objet';
import { autoPlay } from 'react-swipeable-views-utils';
import SwipeableViews from 'react-swipeable-views';
import { urlFor } from '../configs/sanityClient';
import { borderRadius, fontWeight } from '@mui/system';
import { shadow } from 'pdfjs-dist';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const constantImageUrl = 'https://static.vecteezy.com/system/resources/thumbnails/035/147/887/small/ai-generated-antique-armchair-throne-on-transparent-background-image-png.png';

const getImageUrl = (image) => {
  try {
    const assetRef = image.file?.asset?._ref;
    if (assetRef) {
      return urlFor(image.file).url();
    }
  } catch (error) {
    console.error('Error building Sanity image URL:', error);
  }
  return constantImageUrl;
};

const NewArrivals = () => {

  const dispatch = useDispatch();
  const objets = useSelector((state) => state.objet.items);
  const limitedObjets = objets ? objets.slice(0, 5) : [];

  useEffect(() => {
    dispatch(latestObjets());
  }, [dispatch]);


  return (
    <div style={styles.newArrivals}>
      <h2 style={styles.title}>New Arrivals</h2>
      <a href="/tousLesObjets" style={styles.discoverMore}>Discover More</a>
      <div style={styles.cardContainer}>
        {limitedObjets && limitedObjets.length > 0 ? (
          limitedObjets.map((objet) => (
            <div key={objet.dbId} style={styles.card}>
              <div style={styles.cardDetails}>
                <h3 style={styles.cardTitle}>{objet.nom}</h3>
                <AutoPlaySwipeableViews enableMouseEvents={false}>
                  {objet.images.length > 0 ? (
                    objet.images.map((image, i) => (
                      <img
                        key={i}
                        src={image?.path || getImageUrl(image)}
                        alt={`Objet Image ${i}`}
                        style={styles.cardImage}
                      />
                    ))
                  ) : (
                    <img
                      src={constantImageUrl}
                      alt="Default Image"
                      style={styles.cardImage}
                    />
                  )}
                </AutoPlaySwipeableViews>
                <div style={styles.infoContainer}>
                  <span style={styles.pays}>{objet.pays ? objet.pays.nom : "Unknown Country"}</span>
                  <span style={styles.price}>{objet.prix}€</span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p style={styles.noArrivals}>No new arrivals available</p>
        )}
      </div>
    </div>
  );
};

const styles = {
  newArrivals: {
    textAlign: 'center',
    backgroundColor: '#232323',
    padding: '20px',
    color: '#e0e0e0',
  },
  title: {
    fontSize: '1.5rem',
    marginBottom: '10px',
    color: '#A68E5D',
    fontFamily: 'Georgia',
  },
  discoverMore: {
    fontSize: '1rem',
    color: '#ccc',
    textDecoration: 'none',
    marginBottom: '20px',
    display: 'inline-block',
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '27px',
    flexWrap: 'wrap',
  },
  card: {
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    width: '200px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    textAlign: 'left',
    color: '#333',
    padding: '10px',
  },
  cardImage: {
    width: '100%',
    height: '200px',
    objectFit: 'cover',
    display: 'block',
    borderRadius: "10px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)"
  },
  cardDetails: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
  },
  cardTitle: {
    fontSize: '1rem',
    marginTop: '-15px',
    fontFamily: "Poppins, sans-serif",
    fontWeight: 100,
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '10px',
  },
  price: {
    fontWeight: 'bold',
    fontSize: '1rem',
    color: '#b5a642',
    fontFamily: "Poppins, sans-serif",
    fontWeight: 100
  },
  pays: {
    fontSize: '1rem',
    color: '#333',
    fontFamily: "Poppins, sans-serif",
    fontWeight: 100
  },
  noArrivals: {
    color: '#ccc',
    fontSize: '1rem',
    fontFamily: "Poppins, sans-serif",
    fontWeight: 100
  },
};

export default NewArrivals;
