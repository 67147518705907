import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  Link
} from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import { useTheme } from '@mui/material/styles';
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories } from "../store/categories";
import { useNavigate, useLocation } from "react-router-dom";
import SignUpModal from '../auth/signUp';
import { loginSuccess, logout } from "../store/auth";
import LoginModal from '../auth/logIn';
import { setSignUpModal, setSignInModal } from "../store/utilisateur";

const Navbar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const categories = useSelector((state) => state.categories.items || []);
  const searchParams = new URLSearchParams(location.search);
  const selectedCategoryId = searchParams.get("category");
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const signUp = useSelector((state) => state.utilisateur.isSignUpModalOpen);
  const signIn = useSelector((state) => state.utilisateur.isLoginModalOpen);

  const handleSignup = () => {
    setIsSignUpModalOpen(true);
  };

  const handleLogin = () => {
    setIsLoginModalOpen(true);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    dispatch(logout());
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));
    if (token) {
      dispatch(loginSuccess());
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch, location.search]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleCategorySelect = (categorieId) => {
    if (selectedCategoryId === categorieId) {
      navigate(`/tousLesObjets`);
    } else {
      navigate(`/tousLesObjets?category=${categorieId}`);
    }
    setMobileOpen(false);
  };

  const drawer = (
    <Box sx={{ display: "flex", flexDirection: "column", backgroundColor: "#232323", height: "100%", width: 250 }}>
      <List sx={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "center" }}>
        {!isLoggedIn && (
          <ListItem button onClick={handleLogin}>
            <ListItemText primary="Sign In" sx={{ color: "#A68E5D" }} />
          </ListItem>
        )}
        {isLoggedIn && categories?.length > 0 ? (
          categories.map((categorie) => (
            <Button
              key={categorie._id}
              sx={{
                color: selectedCategoryId === categorie._id ? "#232323" : "#A68E5D",
                mx: 2,
                backgroundColor: selectedCategoryId === categorie._id ? "#A68E5D" : "transparent",
                "&:hover": {
                  backgroundColor: "#8B6E3C",
                },
              }}
              onClick={() => handleCategorySelect(categorie._id)}
            >
              {categorie.nom}
            </Button>
          ))
        ) : (
          <Typography variant="h6" sx={{ color: "#A68E5D" }}>
            {isLoggedIn ? "No categories available" : ""}
          </Typography>
        )}
      </List>
      <Link href="/contact" sx={{ alignSelf: "center" }}>
        <Button
          variant="text"
          sx={{
            color: "#A68E5D",
            textTransform: "none",
            display: "flex",
            alignItems: "center",
            fontFamily: "'Poppins', serif",
          }}
        >
          Contactez-nous?
        </Button>
      </Link>
      {isLoggedIn && <Box sx={{ position: "absolute", cursor: "pointer", bottom: 20, display: "flex", flexDirection: "row", gap: 1, ml: "1.5rem" }}
        onClick={handleLogout}
      >
        <LogoutIcon sx={{ color: "#A68E5D", fontSize: 20 }} onClick={handleLogout}></LogoutIcon>
        <Typography
          variant="h4"
          sx={{ color: "#A68E5D", fontFamily: "serif", fontWeight: "bold", textAlign: "center", fontSize: isMobile ? "1rem" : "2rem" }}
        >
          Se déconnecter
        </Typography>
      </Box>}
    </Box>
  );

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: "#232323" }}>
        <Toolbar sx={{ alignItems: "center", justifyContent: "center", width: isMobile ? "90%" : 5 / 6, alignSelf: "center" }}>
          <IconButton
            edge="start"
            sx={{ display: { xs: "block", sm: "none" }, color: "#A68E5D", mr: 2, position: "absolute", left: 20 }}
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: { xs: "row", sm: "row" },
              cursor: "pointer" // Make the title clickable
            }}
            onClick={() => navigate('/')} // Navigate to home on title click
          >
            <Typography
              variant="h4"
              sx={{ color: "#A68E5D", fontFamily: "serif", fontWeight: "bold", textAlign: "center", fontSize: isMobile ? "1.5rem" : "2rem" }}
            >
              L'ART ENTRE NOUS
            </Typography>
            <img
              src="https://static.vecteezy.com/system/resources/thumbnails/035/147/887/small/ai-generated-antique-armchair-throne-on-transparent-background-image-png.png"
              alt="Louis XV Logo"
              height="41px"
            />
          </Box>
          {!isLoggedIn ? (
            <Box sx={{ display: { xs: "none", sm: "flex" }, position: "absolute", right: "2.6rem" }}>
              <Button sx={{ color: "#A68E5D", ml: 2 }} onClick={handleSignup}>Registre</Button>
              <Button
                sx={{
                  ml: 2,
                  backgroundColor: "#A68E5D",
                  color: "#232323",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#8B6E3C",
                  },
                }}
                onClick={handleLogin}
              >
                Connecter
              </Button>
            </Box>
          ) : (
            <Box sx={{ display: { xs: "none", sm: "flex" }, position: "absolute", right: "2.6rem", cursor: "pointer" }}>
              <LogoutIcon sx={{ color: "#A68E5D" }} onClick={handleLogout}></LogoutIcon>
            </Box>
          )}
        </Toolbar>
        {isLoggedIn && (
          <Toolbar sx={{ justifyContent: "center", display: { xs: "none", sm: "flex" } }}>
            {categories.length > 0 ? (
              categories.map((categorie) => (
                <Button
                  key={categorie._id}
                  sx={{
                    fontFamily: "Poppins, sans-serif",
                    color: selectedCategoryId === categorie._id ? "#232323" : "#A68E5D",
                    mx: 2,
                    backgroundColor: selectedCategoryId === categorie._id ? "#A68E5D" : "transparent",
                    "&:hover": {
                      backgroundColor: "#8B6E3C",
                    },
                  }}
                  onClick={() => handleCategorySelect(categorie._id)}
                >
                  {categorie.nom}
                </Button>
              ))
            ) : (
              <Typography variant="h6" sx={{ color: "#A68E5D" }}>
                No categories available
              </Typography>
            )}
          </Toolbar>
        )}

        <Drawer
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          sx={{ display: { xs: "block", sm: "none" } }}
        >
          {drawer}
        </Drawer>
      </AppBar>

      {isSignUpModalOpen && (
        <SignUpModal
          key={signUp}
          open={isSignUpModalOpen}
          onClose={() => dispatch(setSignUpModal(false))}
        />
      )}
      {isLoginModalOpen && (
        <LoginModal
          key={signIn}
          open={isLoginModalOpen}
          onClose={() => dispatch(setSignInModal(false))}
          handleSignup={handleSignup}
        />
      )}
    </>
  );
};

export default Navbar;
