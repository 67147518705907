
import React, { useEffect, useState } from "react";
import { Box, Typography, Card, CardMedia, Button, Link, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { useNavigate } from 'react-router-dom';
import { fetchPays } from "../store/pays";
import { fetchObjets } from "../store/objet";
import { fetchCategories } from "../store/categories";
import { useDispatch, useSelector } from "react-redux";
import ObjetCard from './ObjetCard';
import louis from '../assets/louis.png'
import headerImg from "../assets/headerImg.png"
import NewArrivals from "./NewArrivals";
import { setSignUpModal, setSignInModal } from "../store/utilisateur";
import LoginModal from '../auth/logIn';
import SignUpModal from '../auth/signUp';

const constantImageUrl = 'https://static.vecteezy.com/system/resources/thumbnails/035/147/887/small/ai-generated-antique-armchair-throne-on-transparent-background-image-png.png';
const title = "L'élégance partagée";
const description = "L’art entre nous est un réseau privé international de mise en relation pour les amateurs et collectionneurs qui souhaitent acheter, vendre ou échanger toutes sortes d’objets d’arts antiques et contemporains, tapis précieux, meubles anciens, en toute simplicité.";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


const SingleCard = () => {
  const dispatch = useDispatch();
  const pays = useSelector((state) => state.pays.items);
  const objets = useSelector((state) => state.objet.items);
  const categoriesStore = useSelector((state) => state.categories.items);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);//
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);//
  const signUp = useSelector((state) => state.utilisateur.isSignUpModalOpen)
  const signIn = useSelector((state) => state.utilisateur.isLoginModalOpen)


  const handleSignup = () => {
    setIsSignUpModalOpen(true);
  };

  const handleLogin = () => {
    setIsLoginModalOpen(true);
  };

  useEffect(() => {
    dispatch(fetchPays());
    dispatch(fetchObjets());
    dispatch(fetchCategories());
  }, [dispatch]);

  const uniqueObjetsByCategory = {};
  objets.forEach((objet) => {
    if (!uniqueObjetsByCategory[objet?.categorie?._id]) {
      uniqueObjetsByCategory[objet?.categorie?._id] = objet;
    }
  });

  const objetsToShow = Object.values(uniqueObjetsByCategory);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        px: 3,
        backgroundColor: "#232323",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* Home Screen Header  */}
      <Card
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row-reverse",
          width: isMobile ? "90%" : 5 / 6,
          backgroundColor: "#232323",
          border: "2px solid #A68E5D",
          boxShadow: 3,
          borderRadius: 6,
          overflow: "hidden",
        }}
      >
        <CardMedia
          component="img"
          sx={{
            height: isMobile ? "auto" : "500px",
            width: isMobile ? "100%" : "550px",
            objectFit: "cover",
            borderRadius: isMobile ? "0" : "20px",
            padding: isMobile ? "0" : "10px",
            boxSizing: "border-box",
          }}
          image={louis}
          alt="Constant Image"
        />
        <Box
          sx={{
            width: isMobile ? "100%" : "60%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            color: "#A68E5D",
          }}
        >
          <Typography
            component="div"
            variant="h2"
            sx={{ mb: 1, mt: isMobile ? 2 : 0, fontSize: isMobile ? "2.2rem" : "3.5rem", width: isMobile ? "100%" : 1 / 2, alignSelf: "center", fontFamily: "Georgia ,serif" }}
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            color="grey"
            sx={{ fontSize: isMobile ? "1rem" : "1.1rem", width: 3 / 4, alignSelf: "center", fontFamily: "Poppins ,serif" }}
          >
            {description}
          </Typography>

          <Button
            sx={{
              fontFamily: "Poppins ,serif",
              color: "#A68E5D",
              ml: 2,
              border: 1,
              borderColor: "#A68E5D",
              width: isMobile ? 2 / 5 : 1 / 4,
              alignSelf: "center",
              marginTop: 3,
              mb: isMobile ? 2 : 0,
            }}
            onClick={() => {
              if (isLoggedIn) {
                navigate('/tousLesObjets');
              } else {
                handleSignup();

              }
            }}
          >
            Acheter Maintenant
          </Button>

        </Box>
      </Card>

      {!isLoggedIn && <Box
        sx={{
          mt: 6,
          width: 5 / 6,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Link href="/contact">
          <Button
            variant="text"
            sx={{
              color: "#A68E5D",
              textTransform: "none",
              display: "flex",
              alignItems: "center",
              fontFamily: "'Poppins', serif",
            }}
          >
            Contactez-nous?
          </Button>
        </Link>
        <Box
          sx={{
            width: "50%", // Using percentage instead of fraction for better readability
            display: "flex",
            justifyContent: "flex-end", // Updated "end" to "flex-end" for proper alignment
            gap: "16px",
          }}
        >
          <Button
            variant="text"
            sx={{
              color: "#A68E5D",
              textTransform: "none",
              display: "flex",
              alignItems: "center",
              fontFamily: "'Poppins', serif", // Fixed font-family syntax
            }}
            onClick={handleSignup}  >
            Etre membre ?
          </Button>
          <Button
            variant="text"
            sx={{
              color: "black",
              textTransform: "none",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#A68E5D",
              "&:hover": {
                backgroundColor: "#8B6E3C",
              },
              fontFamily: "'Poppins', serif", // Fixed font-family syntax
              fontWeight: 300,
              px: 4,
            }}
            onClick={handleLogin}
          >
            Déjà membre
          </Button>
        </Box>

      </Box>}

      {/* Countries Cards  */}
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",

          width: "100%",
        }}
      >
        <Grid container spacing={2} sx={{ justifyContent: 'center', py: 2, mt: 2, mb: 2 }}>
          {pays.map((pay) => (
            <Grid item key={pay._id} xs={12} sm={4} md={2}>
              <Card
                sx={{
                  alignItems: "center",
                  backgroundColor: '#232323',
                  border: "2px solid #404040",
                  borderRadius: 3,
                  boxShadow: 6,
                  color: '#A68E5D',
                  py: 3,
                  width: 5 / 5,
                }}
              >
                <CardMedia
                  component="img"
                  height="140"
                  image={pay.image[0]?.path || constantImageUrl}
                  alt={pay.image[0]?.alt || "Default Image"}
                  sx={{
                    width: '70px',
                    height: '70px',
                    objectFit: 'fit',
                    margin: 'auto',
                    borderRadius: '50%',
                    boxShadow: 6,
                  }}
                />
                <Box sx={{ padding: 1 }}>
                  <Typography variant="h5" sx={{ color: "#A68E5D", fontFamily: "Poppins, sans-serif", fontSize: "1.3rem", textTransform: "uppercase", padding: "0.5rem" }}>
                    {pay.nom}
                  </Typography>

                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>





      {/* object cards */}
      {isLoggedIn &&
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            width: "100%",
            mt: 4,
            backgroundColor: "#232323",
            paddingBottom: 2,
          }}
        >
          <Grid container spacing={2} justifyContent="center">
            {objetsToShow && objetsToShow.map((objet, index) => (
              <Grid item xs={12} sm={6} md={4} lg={2} key={index}>
                <ObjetCard objet={objet} />
              </Grid>
            ))}
          </Grid>
          {objets && (
            <Box sx={{ textAlign: "center", mt: 4 }}>
              <Button
                onClick={() => navigate('/tousLesObjets')}
                sx={{
                  backgroundColor: "#A68E5D",
                  color: "#232323",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#8B6E3C",
                  },
                }}
              >
                Voir plus
              </Button>

            </Box>
          )}
        </Box>}
      {isLoggedIn && <NewArrivals></NewArrivals>}
      {isSignUpModalOpen && (
        <SignUpModal
          key={signUp}
          open={isSignUpModalOpen}
          onClose={() => dispatch(setSignUpModal(false))}
        />
      )}
      {isLoginModalOpen && (
        <LoginModal
          key={signIn}
          open={isLoginModalOpen}
          onClose={() => dispatch(setSignInModal(false))}
          handleSignup={handleSignup}
        />
      )}
    </Box>
  );
};

export default SingleCard;